import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'

import './lkEditPage.css';
import { useEffect } from 'react';
import getCookie from '../../functions/getCookie';
import jsonToUrlEncoded from '../../functions/jsonToUrlEncoded';
import PickupMap from '../../components/PickUpMap';
import { useDispatch } from 'react-redux';
import { userActions } from '../../redux/userReducer';

export default function LkEditPage() {
    const navigate = useNavigate();
    const [data, setData] = useState()
    const [pvz, setPVZ] = useState();
    const [isModalOpen, setModalOpen] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        fetch(`${process.env.REACT_APP_URL}/lk/edit`, {
            headers: {
                Authorization: `Bearer ${getCookie('authorization')}`
            }
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error("Ошибка запроса");
                }
                return response.json();
            })
            .then(data => {
                setData(data.info);
            })
            .catch(error => {
                console.error("Ошибка при обработке ответа:", error);
            });
    }, [])

    function onChange(e) {
        const newData = { ...data, [e.target.id]: e.target.value };
        setData(newData);
    }

    function handleCountBookChange(e) {
        const input = e.target.value;
        // Регулярное выражение, позволяющее оставить только числа в строке
        const regex = /^[0-9]*$/;
        if (regex.test(input)) {
            onChange(e);
        }
    }

    function handleOnSave(e) {
        fetch(`${process.env.REACT_APP_URL}/lk/edit`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${getCookie('authorization')}`
            },
            body: JSON.stringify(data)
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Ошибка соединения');
                }
                return response.json();
            })
            .then(data => {
                navigate('/lk')
            })
            .catch(error => {
                console.error('There was an error!', error);
            });
        e.preventDefault()
    }

    const handlePickupSelect = (e) => {
        const newData = {
            ...data,
            favoritPVZ: {
                service: e.service,
                code: e.code,
            }
        };

        setData(newData);
        dispatch(userActions.updateFavPvz({
            favoritPVZ: {
                service: e.service,
                code: e.code,
            }
        }))
        setModalOpen(false)
    }

    useEffect(() => {
        if (data?.favoritPVZ) {
            fetch(`${process.env.REACT_APP_URL}/delivery/cdek/${data.favoritPVZ.code}`, {
                headers: { Authorization: `Bearer ${getCookie('authorization')}` },
            })
                .then((res) => res.json())
                .then((data) => {
                    const { location } = data[0];
                    setPVZ(`СДЕК, ${location.city}, ${location.address}`);
                });
        }
    }, [data?.favoritPVZ])

    return (
        <>
            <form className="lkEditForm">
                <div className="lkEditColumn">
                    <h2 className='lkEditTitle'>Избранный пункт выдачи</h2>
                    <div className="inputContainer" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <>
                            {data?.favoritPVZ?.code && pvz}
                            <button className='lkButton' onClick={(e) => { e.preventDefault(); setModalOpen(true) }}>Выбрать пункт выдачи</button>
                        </>
                    </div>

                    <h2 className='lkEditTitle'>Инфо</h2>
                    <div className="inputContainer">
                        <label className="inputLabel" htmlFor="profession">Род деятельности</label>
                        <input className="lkEditFormInput" type="text" name="profession" id="profession" value={data?.profession} onChange={onChange} />
                    </div>
                    <div className="inputContainer">
                        <label className="inputLabel" htmlFor="countBook">Сколько книг в год?</label>
                        <input className="lkEditFormInput" type="text" name="countBook" id="countBook" value={data?.countBook} onChange={handleCountBookChange} />
                    </div>
                </div>
                <div className="lkEditColumn">
                    <h2 className='lkEditTitle'>Связь</h2>
                    <div className="inputContainer">
                        <label className="inputLabel" htmlFor="site">Сайт</label>
                        <input className="lkEditFormInput" type="text" name="site" id="site" value={data?.site} onChange={onChange} />
                    </div>
                    <div className="inputContainer">
                        <label className="inputLabel" htmlFor="vk">Вк</label>
                        <input className="lkEditFormInput" type="text" name="vk" id="vk" value={data?.vk} onChange={onChange} />
                    </div>
                    <div className="inputContainer">
                        <label className="inputLabel" htmlFor="telegram">Telegram</label>
                        <input className="lkEditFormInput" type="text" name="telegram" id="telegram" value={data?.telegram} onChange={onChange} />
                    </div>
                    <div className="inputContainer">
                        <label className="inputLabel" htmlFor="whatsapp">WhatsApp</label>
                        <input className="lkEditFormInput" type="text" name="whatsapp" id="whatsapp" value={data?.whatsapp} onChange={onChange} />
                    </div>
                    <button onClick={handleOnSave} className="lkEditSave">Сохранить</button>
                </div>
            </form>

            {isModalOpen && (
                <div className='pickUpModal_overlay'>
                    <div className="pickUpModal">
                        <button className="modal_close_button" onClick={(e) => { e.preventDefault(); setModalOpen(false) }}>
                            <span className="modal_close_icon">&times;</span>
                        </button>
                        <div className="pickUpModalContent" style={{ marginTop: '20px' }}>
                            <PickupMap type='sdek' onPickupSelect={handlePickupSelect} withoutPrice />
                        </div>
                    </div>
                </div>
            )}


        </>
    );
}
