
export default function ErrorModal({ errorMessage, onClose }) {
    return (
        <div className="errorModal_overlay" onClick={onClose}>
            <div className="errorModal" onClick={(e) => e.stopPropagation()}>
                <button className="modal_close_button" onClick={onClose}>
                    <span className="modal_close_icon">&times;</span>
                </button>
                <div className="errorModalContent">
                    <h2>Ошибка</h2>
                    {errorMessage.map((el, index) => {
                        return <p key={index}>{el}</p>
                    })}
                </div>
            </div>
        </div>
    );
};